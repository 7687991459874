<template lang="pug">

.vc-dashboard-user-info-card
  .user-card
    .info-wrapper(v-if="currentUserData.isDataLoaded()")
      .avatar
        img(src="https://via.placeholder.com/100x100")
      .name-and-level
        .name
          span {{ currentUserData.name }}
        .level
          //- TODO: add member_level column to User model
          .level-label(:class="currentUserData.member_level") {{ enumLocaleText('user', 'member_level', currentUserData.member_level) }}

    .account-option-wrapper
      //- TODO: remove hashtag when settings page done
      a.option.account-settings(href="/user/settings")
        .icon
          i.fa.fa-gear
        span
          | {{ actionLocaleText('account_settings') }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    currentUserData() {
      return this.$store.getters['users/find'](this.currentUser.id)
    },
  },

  created() {
    if (!this.currentUserData.isDataLoaded()) this.$store.dispatch('users/find', this.currentUser.id)
  },
  // mounted() {},
  // methods: {}
}
</script>
