<template lang="pug">

.vc-distribution-base-unit
  .location-info
    .icon
      i.fa.fa-shopping-bag
    span {{ distributionBase.location_name }}
  .basic-info
    .base-title
      h3.title {{ distributionBase.title }}
    .base-address
      span.prefix {{ attributeLocaleText('distribution_base', 'address') }}
      span {{ distributionBase.address }}
  .option-buttons
    a.button.is-default.google-map-link(:href="googleMapUrl"
                                        target="_blank"
                                        rel="noopener")
      .icon
        i.fa.fa-map-marker
      span.text Google Map
    .button.is-default.detail-info-button(@click="showDetailBox")
      .icon
        i.fa.fa-search
      span.text {{ actionLocaleText('detail_info') }}

</template>

<script>
import DetailBox from './detail-box.vue'

export default {
  components: {
    DetailBox,
  },
  // mixins: [],
  props: {
    distributionBase: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    googleMapUrl() {
      return `https://www.google.com/maps/search/?api=1&query=${this.distributionBase.address}`
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    showDetailBox() {
      this.$buefy.modal.open({
        parent: this,
        component: DetailBox,
        width: 320,
        props: {
          distributionBase: this.distributionBase,
        },
      })
      this.$store.dispatch('pixelOperation', [['track', 'FindLocation']])
    },
  },
}
</script>
