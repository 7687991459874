// import priceTagIcon from '../../../images/price-tag-invert.svg'
import CollectButton from '../product/collect-button.vue'
import PurchaseButton from '../product/purchase-button.vue'

export default {
  components: {
    CollectButton,
    PurchaseButton,
  },
  // mixins: [],
  props: {
    product: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: false,
    },

    listLocation: {
      type: String,
      required: false,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    // onsaleIndicatorIcon() {
    //   return priceTagIcon
    // },

    productLink() {
      return `/products/${this.product.slug || this.product.id}`
    },
  },
  // created() {},
  mounted() {
    this.sendGaImpressionEvent()
  },

  methods: {
    sendGaImpressionEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addImpression',
            {
              id: this.product.sku,
              name: this.product.name,
              brand: this.product.brand_name,
              list: this.listLocation,
              position: this.index + 1,
              price: this.toMoney(this.product.consumer_price).amount,
            },
          ],
        ])
        .then((_) => {
          this.$emit('ga-impressed')
        })
    },

    sendGaClickEvent() {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:addProduct',
          {
            id: this.product.sku,
            name: this.product.name,
            brand: this.product.brand_name,
            position: this.index + 1,
            price: this.toMoney(this.product.consumer_price).amount,
          },
        ],
        [
          'ec:setAction',
          'click',
          {
            list: this.listLocation,
          },
        ],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'UX',
            eventAction: 'click',
            eventLabel: this.listLocation,
            transport: 'beacon',
          },
        ],
      ])
    },
  },
}
