import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'product_support_documents',
  attributes: ['id', 'title', 'type', 'is_image', 'file', 'original_filename', 'product', 'created_at'],
  editableAttributes: ['title', 'file'],
}

export default class ProductSupportDocument extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
