<template lang="pug">

.vc-common-fb-messenger
  .odd-container.has-padding
    a.messenger-icon(:href="fbMessengerMMeLink"
                     target="_blank")
      img(:src="FbmessengerIcon")

</template>

<script>
import FbmessengerIcon from '../../../images/messenger.svg'

export default {
  // components: {},
  // mixins: [],

  props: {
    fbMessengerMMeLink: {
      type: String,
      required: true,
    },
  },

  // data() {
  //   return {}
  // },

  computed: {
    FbmessengerIcon() {
      return FbmessengerIcon
    },
  },
  // created() {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
