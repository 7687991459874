const FILE_TYPES_MAP = {
  // 壓縮
  'application/x-bzip': {
    type: 'compressed',
    icon: 'fa-file-archive-o',
  },
  'application/x-bzip2': {
    type: 'compressed',
    icon: 'fa-file-archive-o',
  },
  'application/x-rar-compressed': {
    type: 'compressed',
    icon: 'fa-file-archive-o',
  },
  'application/gzip': {
    type: 'compressed',
    icon: 'fa-file-archive-o',
  },
  'application/zip': {
    type: 'compressed',
    icon: 'fa-file-archive-o',
  },
  'application/x-7z-compressed': {
    type: 'compressed',
    icon: 'fa-file-archive-o',
  },

  // Image
  'image/gif': {
    type: 'image',
    icon: 'fa-file-image-o',
  },
  'image/jpeg': {
    type: 'image',
    icon: 'fa-file-image-o',
  },
  'image/png': {
    type: 'image',
    icon: 'fa-file-image-o',
  },
  'image/svg+xml': {
    type: 'image',
    icon: 'fa-file-image-o',
  },

  // Word
  'application/msword': {
    type: 'word',
    icon: 'fa-file-word-o',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    type: 'word',
    icon: 'fa-file-word-o',
  },

  // Excel
  'application/vnd.ms-excel': {
    type: 'excel',
    icon: 'fa-file-excel-o',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    type: 'excel',
    icon: 'fa-file-excel-o',
  },

  // Powerpoint
  'application/vnd.ms-powerpoint': {
    type: 'powerpoint',
    icon: 'fa-file-powerpoint-o',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    type: 'powerpoint',
    icon: 'fa-file-powerpoint-o',
  },

  // PDF
  'application/pdf': {
    type: 'pdf',
    icon: 'fa-file-pdf-o',
  },

  // Text
  'text/plain': {
    type: 'text',
    icon: 'fa-file-text-o',
  },
  'text/markdown': {
    type: 'text',
    icon: 'fa-file-text-o',
  },
}

export default class FileTypeService {
  static call(fileType) {
    return FILE_TYPES_MAP[fileType]
  }
}
