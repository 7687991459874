<template lang="pug">

.vc-info-link-use-type-container
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  info-box(v-for="infoLink in infoLinks"
           :key="`${useType}-${infoLink.id}`"
           :info-link="infoLink")
</template>

<script>
import InfoBox from './info-box.vue'

export default {
  components: {
    InfoBox,
  },
  // mixins: [],
  props: {
    useType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
    }
  },

  computed: {
    infoLinks() {
      return this.$store.getters['infoLinks/allByType'](this.useType)
    },
  },

  created() {
    this.$store
      .dispatch('infoLinks/all', {
        keep: true,
        filter: this.useType,
        sort: 'position',
      })
      .then((_) => {
        this.isLoading = false
      })
  },
  // mounted() {},
  // methods: {}
}
</script>
