<template lang="pug">

.vc-order-comment-button.button.is-outlined.is-default(@click="openOrderCommentForm")
  .icon
    i.fa(:class="iconType")
  span.text {{ actionLocaleText('contact_customer_service') }}

</template>

<script>
import CommentForm from './comment-form.vue'

export default {
  components: {
    CommentForm,
  },
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    iconType() {
      return {
        'fa-comments-o': !this.order.has_new_reply,
        'fa-commenting animated infinite jello': this.order.has_new_reply,
      }
    },
  },
  // created() {},
  mounted() {
    if (window.location.search.includes('show_comments=1')) this.openOrderCommentForm()
  },

  methods: {
    openOrderCommentForm() {
      this.$buefy.modal.open({
        parent: this,
        component: CommentForm,
        width: 320,
        props: {
          order: this.order,
        },
      })
    },
  },
}
</script>
