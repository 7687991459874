<template lang="pug">

a.vc-dashboard-profile-side-menu-unit(href="#"
                                      :class="menuState"
                                      @click.prevent="menuSelectedHandler")
  span {{ copyLocaleText(menuName) }}
  .icon
    i.fa.fa-angle-right

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    menuName: {
      type: String,
      required: true,
    },

    selectedMenu: {
      type: String,
      required: false,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isSelected() {
      return this.menuName === this.selectedMenu
    },

    menuState() {
      return {
        'is-active': this.isSelected,
      }
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    menuSelectedHandler() {
      if (this.isSelected) return

      this.$emit('menu-selected', this.menuName)
    },
  },
}
</script>
