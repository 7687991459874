import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'category_systems',
  attributes: ['id', 'name', 'position', 'is_default', 'category_type', 'created_at', 'updated_at', 'root_category'],
  editableAttributes: [],
}

export default class CategorySystem extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
