<template lang="pug">

.vc-distribution-base-detail-box
  .title-wrapper
    .location
      .icon
        i.fa.fa-shopping-bag
      span {{ distributionBase.location_name }}
    h4.base-title
      span {{ distributionBase.title }}
      a.link(v-if="distributionBase.website"
             :href="distributionBase.website"
             target="_blank"
             ref="noopenner noreferrer")
        .icon
          i.fa.fa-globe
  .detail-info-wrapper
    .info-unit.address
      span.prefix {{ attributeLocaleText('distribution_base', 'address') }}
      span.text {{ distributionBase.address }}
    .info-unit.phone
      span.prefix {{ attributeLocaleText('distribution_base', 'phone') }}
      span.text {{ distributionBase.phone }}
    .business-time
      .unit(v-for="unit in distributionBase.business_time")
        span.weekdays {{ weekdays[unit.start_weekday] }} - {{ weekdays[unit.end_weekday] }}
        span.times {{ unit.start_time }} ~ {{ unit.end_time }}
  .note-wrapper(v-if="distributionBase.note")
    span.prefix {{ attributeLocaleText('distribution_base', 'note') }}
    .text(v-html="simpleFormat(distributionBase.note)")
  .options
    a.detail-info-button.button.is-odd.is-block(:href="detailPagePath") {{ copyLocaleText('distribution_base_detail_page') }}


</template>

<script>
import moment from 'moment'

export default {
  // components: {},
  // mixins: [],
  props: {
    distributionBase: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    weekdays() {
      return moment.localeData().weekdaysShort()
    },

    detailPagePath() {
      return `/distribution_bases/${this.distributionBase.id}`
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
