<template lang="pug">

.vc-common-search-button.function-button
  b-dropdown(position="is-bottom-left")
    a.link(slot="trigger"
           href="#"
           @click.prevent="")
      .icon.search
        .fa.fa-search
      //- span.text {{ actionLocaleText('search') }}
    b-dropdown-item(custom)
      b-field(:label="actionLocaleText('search')")
        input.input(type="text"
                    v-model="searchText"
                    :placeholder="messageLocaleText('exter_product_name_or_key_word')"
                    @keydown.enter="search"
                    @compositionend="composing=false"
                    @compositionstart="composing=true")

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      modalActived: false,
      searchText: '',
      composing: false,
    }
  },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    openSearchModal() {
      this.modalActived = true
    },

    search() {
      if (this.composing) return

      Turbolinks.visit(encodeURI(`/products?q[variants_with_master_sku_or_brand_name_or_name_cont]=${this.searchText}`))
    },
  },
}
</script>
