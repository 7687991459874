<template lang="pug">

.vc-product-search-box.tool
  input.input(v-model="searchText"
              :placeholder="messageLocaleText('exter_product_name_or_key_word')"
              type="text"
              @keydown.enter="searchOptionUpdated"
              @compositionend="composing=false"
              @compositionstart="composing=true")
  a.icon(@click.prevent="searchOptionUpdated")
    i.fa.fa-search

</template>

<script>
import queryString from 'query-string'

export default {
  // components: {},
  // mixins: [],
  // props: {},

  data() {
    return {
      searchText: null,
      composing: false,
    }
  },

  // computed: {},
  // created() {},
  mounted() {
    let currentQueryString = queryString.parse(window.location.search)
    this.searchText = currentQueryString['q[variants_with_master_sku_or_brand_name_or_name_cont]']

    if (this.searchText) this.searchOptionUpdated()

    this.$emit('update:search-options-ready', true)
  },

  methods: {
    searchOptionUpdated() {
      if (!this.composing) {
        this.$store.dispatch('pixelOperation', [['track', 'Search']]).then((_) => {
          this.$emit('update:search-options', encodeURI(this.searchText))
        })
      }
    },
  },
}
</script>
