import Money from 'odd-money'
import customCurrencies from '../custom_currencies.js'

const SignedInScopeTypeMap = {
  user: 'User',
  admin: 'Admin',
}

function install(
  Vue,
  options = {
    envScope: 'user',
  }
) {
  Vue.mixin({
    computed: {
      currentUser() {
        return this.$store.getters['currentUser']
      },

      isUserSignedIn() {
        return this.currentUser.type === SignedInScopeTypeMap[options.envScope]
      },
    },

    methods: {
      simpleFormat(text) {
        if (text) {
          return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }
      },

      toMoney(cents, currency = 'TWD') {
        return new Money(cents, currency, customCurrencies)
      },

      moneyFromAmount(amount, currency = 'TWD') {
        return Money.fromAmount(amount, currency, customCurrencies)
      },

      currentUnixTime() {
        return Math.floor(Date.now() / 1000)
      },
    },
  })
}

export default install
