<template lang="pug">

.vc-common-member-options-button.function-button
  //- For Signed in User
  b-dropdown(v-if="isUserSignedIn")
    .link(slot="trigger")
      .icon
        i.fa.fa-user-circle-o
      span.text {{ linkText }}
    b-dropdown-item(has-link)
      a.logout(href="/user/dashboard")
        .icon
          i.fa.fa-home
        span {{ copyLocaleText('member_center') }}
    b-dropdown-item(has-link)
      a.logout(href="/user/logout"
               data-method="delete")
        .icon
          i.fa.fa-sign-out
        span {{ actionLocaleText('logout') }}

  //- For Guest
  a.link(v-else
         :href="linkPath")
    .icon
      i.fa.fa-user-circle-o
    span.text {{ linkText }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    linkPath() {
      if (this.isUserSignedIn) {
        return '/user/dashboard'
      } else {
        return '/user/login'
      }
    },

    linkText() {
      if (this.isUserSignedIn) {
        return this.copyLocaleText('membership_options')
      } else {
        return this.copyLocaleText('login_or_signup')
      }
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
