<template lang="pug">
.vc-product-card
  .inner
    a.product-image(:href="productLink"
                    @click="sendGaClickEvent")
      img.image(v-lazy="product.coverImageThumb()")
      .indicator(v-if="product.hasDiscount()")
        | {{ product.displayDiscountRate() }}

    .info-wrapper
      .product-name
        .name {{ product.name }}
        .brand(v-if="product.brand_name"
               key="product.brand_name") {{ product.brand_name }}
        .brand.placeholder(v-else
                           key="product.brand_name")
          | &nbsp;
      .price-info
        //- //- if has no discount, this placeholder should hold up height.
        .price-type(v-if="product.hasDiscount()")
          span.type {{ copyLocaleText('msrp') }}
          span.price {{ toMoney(product.original_price).format() }}
        .price-type.placeholder(v-else)
          span.price &nbsp;
        .price-type
          span.type {{ consumerPriceLabeltext }}
          span.price {{ toMoney(product.consumer_price).format() }}

      //- .description
        | {{ product.card_discription }}

    .options-wrapper
      collect-button(:product="product")
      purchase-button(:product="product")

</template>

<script>
import productCardMixin from '../mixins/product_card_mixin.js'

export default {
  // components: {},
  mixins: [productCardMixin],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    consumerPriceLabeltext() {
      return this.product.hasDiscount() ? this.copyLocaleText('discount_price') : this.copyLocaleText('msrp')
    },
  },
  // created() {},
  // mounted() {}
  // methods: {}
}
</script>
